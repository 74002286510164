import React from 'react'
import styles from './app-header.module.scss'
import { IconButton, IconType } from '@alejosdev/components'
import xaviLogo from 'assets/xavi-logo.png'
import cs from 'classnames'
import { useSession } from 'hooks'

interface AppHeaderProps {
    classes?: {
        root?: string
        content?: string
        action?: string
    }
    icon?: IconType
}

const AppHeader: React.FC<AppHeaderProps> = ({ classes, icon = 'bars' }) => {
    const { session, setKey } = useSession()
    const { misc } = session
    return (
        <div className={cs(styles.header, classes?.root)}>
            <div className={styles.logoWrapper}>
                <img src={xaviLogo} alt="app logo" />
            </div>
            <div className={cs(styles.titleContent, classes?.content)}>
                <h2>Xavi</h2>
                <p>one bit at a time</p>
            </div>
            <IconButton
                icon={icon}
                className={cs(styles.actionButton, classes?.action)}
                onClick={() =>
                    setKey('misc', {
                        ...misc,
                        menuOpened: !misc?.menuOpened
                    })
                }
            />
        </div>
    )
}

export default AppHeader
