import {
    Step,
    Stepper,
    StepperActions,
    StepperBack,
    StepperFinish,
    StepperNext,
    TextAreaField
} from '@alejosdev/components'
import { Dialog } from 'components/dialogs'
import StartPoint from './steps/StartPoint'
import { useCallback, useMemo, useState } from 'react'
import ActivityPicker from './steps/ActivityPicker'
import { ActivityScheme } from 'types/schemes/activities.scheme'
import moment from 'moment'
import Summary from './steps/Summary'
import DurationAndTime from './steps/DurationAndTime'
import { useSession } from 'hooks'
import { ActivityFollowUpFormType } from 'types/form-types/activities.form-types'
import useMutateActivityFollowUp from 'graphql/use-mutate-activity-follow-up'

interface ActivityWizardProps {
    onSaved?: () => void
    onStarted?: () => void
    onClose?: () => void
    startingDate?: string | null
    defaultDuration?: number
    defaultType?: 'past' | 'now' | undefined | null
}

const ActivityWizard: React.FC<ActivityWizardProps> = ({
    onStarted,
    onClose,
    onSaved,
    startingDate,
    defaultDuration = 0,
    defaultType = 'now'
}) => {
    const { setAllKeys, session } = useSession()
    const [activityType, setActivityType] = useState<'now' | 'past' | undefined>(
        defaultType || 'now'
    )
    const [selectedActivity, setSelectedActivity] = useState<ActivityScheme | null | undefined>(
        null
    )
    const { create, loading } = useMutateActivityFollowUp()
    const [description, setDescription] = useState<string>('')
    const date = moment().format('YYYY-MM-DD')
    const [started, setStarted] = useState<string>(startingDate || moment().format('HH:mm'))
    const [duration, setDuration] = useState<number>(defaultDuration)
    const stepsValidations = useMemo(() => (activityType === 'now' ? [] : []), [activityType])
    const [enableDuration, setEnableDuration] = useState<'yes' | 'no' | undefined>('no')

    const handleStartActivity = useCallback(async () => {
        const form: ActivityFollowUpFormType = {
            activity_id: selectedActivity?.id || '',
            date,
            started_date: started,
            time_spent: duration,
            description
        }
        setAllKeys({
            activities: {
                ...session.activities,
                inProgress: true,
                activityInProgress: form
            }
        })
        onStarted?.()
    }, [
        selectedActivity,
        date,
        started,
        onStarted,
        session.activities,
        setAllKeys,
        duration,
        description
    ])

    const handleSaveActivity = useCallback(async () => {
        try {
            const form: ActivityFollowUpFormType = {
                activity_id: selectedActivity?.id || '',
                date,
                started_date: `${date} ${started}:00`,
                time_spent: duration,
                description
            }
            const response = await create(form)
            onSaved?.()
        } catch (err) {
            console.log('Error: ', err)
        }
    }, [selectedActivity?.id, date, started, duration, description, create, onSaved])

    const handleFinish = useCallback(() => {
        if (activityType === 'now') {
            handleStartActivity()
        } else {
            handleSaveActivity()
        }
    }, [activityType, handleSaveActivity, handleStartActivity])

    const titles: {
        acitivity?: string
        description?: string
        duration?: string
    } =
        activityType === 'now'
            ? {
                  acitivity: 'What are you doing?',
                  description: 'Leave a brief description',
                  duration: 'Duration'
              }
            : {
                  acitivity: 'What were you doing?',
                  description: 'Leave a brief description',
                  duration: 'What was the duration?'
              }

    return (
        <Dialog open title="Register an activity" disableFooter onClose={onClose} lock={loading}>
            <Stepper stepsValidation={stepsValidations}>
                <Step title="When did you start it?">
                    <StartPoint
                        currentValue={activityType}
                        onChange={(type?: 'now' | 'past') => setActivityType(type)}
                    />
                </Step>
                <Step title={titles?.acitivity}>
                    <ActivityPicker
                        selectedActivity={selectedActivity}
                        onSelect={(activity) => setSelectedActivity(activity)}
                    />
                </Step>
                <Step title={titles?.description}>
                    <TextAreaField
                        autoFocus
                        max={1000}
                        value={description}
                        onChange={({ target: { value } }) => setDescription(value)}
                    />
                </Step>
                <Step title={titles?.duration}>
                    <DurationAndTime
                        type={activityType}
                        duration={duration}
                        started={started}
                        setDuration={(val) => setDuration(val || 0)}
                        setStarted={(val) => setStarted(val || '')}
                        enableDuration={enableDuration}
                        setEnableDuration={setEnableDuration}
                    />
                </Step>
                <Step title="Summary">
                    <Summary
                        type={activityType}
                        activity={selectedActivity}
                        duration={duration}
                        date={date}
                        startedDate={started}
                        description={description}
                    />
                </Step>
                <StepperActions>
                    <StepperBack />
                    <StepperNext />
                    <StepperFinish onClick={handleFinish} />
                </StepperActions>
            </Stepper>
        </Dialog>
    )
}

export default ActivityWizard
