import ExerciseChart from './ExerciseChart'
import WorkChart from './WorkChart'
import styles from './essential-activities-chart.module.scss'

interface EssentialActivitiesChartProps {}

const EssentialActivitiesChart: React.FC<EssentialActivitiesChartProps> = () => {
    return (
        <div className={styles.root}>
            <WorkChart />
            <ExerciseChart />
        </div>
    )
}

export default EssentialActivitiesChart
