import { RoutesConfigType } from '@alejosdev/navigation'
import { LandingPage, LoginPage, RegisterPage, TestingHallPage } from 'pages/unauthenticated'
import {
    DashboardPage,
    HabitsPage,
    TasksPage,
    ActivitiesPage,
    ExpensesPage,
    MyTodosPage,
    MyActivitiesPage
} from 'pages/authenticated'

export const routeAliases = {
    landing: '/',
    login: '/login',
    register: '/register',
    testingHall: '/testing-hall',
    dashboard: '/',
    habits: '/habits',
    tasks: '/tasks',
    activities: '/activities',
    expenses: '/expenses',
    myTodos: '/my-todos',
    myActivities: '/v2/activities'
}

export const routes: RoutesConfigType = {
    authenticated: {
        [routeAliases.dashboard]: DashboardPage,
        [routeAliases.testingHall]: TestingHallPage,
        [routeAliases.habits]: HabitsPage,
        [routeAliases.tasks]: TasksPage,
        [routeAliases.activities]: ActivitiesPage,
        [routeAliases.expenses]: ExpensesPage,
        [routeAliases.myTodos]: MyTodosPage,
        [routeAliases.myActivities]: MyActivitiesPage
    },
    unauthenticated: {
        [routeAliases.landing]: LandingPage,
        [routeAliases.login]: LoginPage,
        [routeAliases.register]: RegisterPage,
        [routeAliases.testingHall]: TestingHallPage
    }
}
