/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from "moment";

type UseTimerOptionsType = {
  hours?: number;
  minutes?: number;
  seconds?: number;
  initialDate?: any;
  duration?: any;
};

const useTimer = (initialValues?: UseTimerOptionsType) => {
  const {
    hours: initialH,
    minutes: initialM,
    seconds: initialS,
    initialDate,
    duration = 0,
  } = initialValues || {};
  const initialDateObj = moment(initialDate, "YYYY-MM-DD HH:mm:ss");
  const [time, setTime] = useState<any>({
    seconds: initialS,
    minutes: initialM,
    hours: initialH,
    clockTime: initialDateObj.format("hh:mm a"),
    clockTimeDate: initialDateObj.format("YYYY-MM-DD HH:mm:ss"),
  });
  let timer: any = null;
  useEffect(() => {
    let elapsedTime = 0;
    let prevElapsedTime = 0;

    timer = setInterval(() => {
      const currentTime = moment();
      elapsedTime = currentTime.diff(initialDateObj, "seconds");
      const elapsedMinutes = currentTime.diff(initialDateObj, "minutes");
      const hours = currentTime.diff(initialDateObj, "hours");
      const minutes = elapsedMinutes - hours * 60;
      const seconds = elapsedTime - elapsedMinutes * 60;

      if (elapsedTime !== prevElapsedTime) {
        prevElapsedTime = elapsedTime;
        setTime({
          seconds,
          minutes,
          hours,
          overPassed: elapsedMinutes > duration * 60,
          elapsed: elapsedTime,
          clockTime: currentTime.format("hh:mm a"),
          clockTimeDate: currentTime.format("YYYY-MM-DD HH:mm:ss"),
        });
      }
    }, 120);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const format = (num: any) => `${num < 10 ? "0" : ""}${num}`;
  const formattedAlt = `${format(time.hours)}:${format(time.minutes)}`;
  const { hours, minutes, seconds, overPassed, clockTime, clockTimeDate } =
    time;
  return {
    formattedAlt,
    time: { hours, minutes, seconds },
    overPassed,
    clockTime: clockTime,
    clockTimeDate,
    elapsed: Math.floor(time.elapsed / 60),
  };
};

export default useTimer;
