import { useMemo } from 'react'
import { TypedDocumentNode, useQuery } from 'urql'

export type GqlOptionsType<VariablesType extends Object = {}> = {
    pagination?: boolean
    variables?: VariablesType
    lazy?: boolean
}
/**
 * This function centrilizes all list requests.
 *
 * @param query
 * @param options
 * @returns
 */
const useGqlQuery = <V extends Object = {}, ResponseType extends Object = {}>(
    query: string | TypedDocumentNode<any>,
    options?: GqlOptionsType<V>
): [ResponseType, boolean, any] => {
    const { lazy = false, pagination = true, variables = {} } = options || {}

    const [results, refetch] = useQuery({
        query,
        variables,
        pause: lazy
    })
    const { fetching, data, error } = results

    const { response, meta } = useMemo(() => {
        if (data?.response && pagination) {
            const { data: resultedData, ...meta } = data.response as {
                current_page: number
                data: any
                from: number
                has_more_pages: boolean
                last_page: number
                per_page: number
                to: number
                total: number
            }
            return { response: resultedData, meta }
        } else {
            return { response: data?.response || [], meta: {} }
        }
    }, [data, pagination])

    const handleRefetch = () => {
        refetch({ requestPolicy: 'network-only' })
    }
    return [response as ResponseType, fetching, { error, meta, refetch: handleRefetch }]
}

export default useGqlQuery
