import { routes, routeAliases } from 'config/navigation.config'
import ApiClient from '@alejosdev/api-provider'
import endpoints from 'config/endpoints.config'
import { useSession } from 'hooks'
import MainLayout from 'layouts/main'
import EmptyLayout from 'layouts/empty'
import AppRouterProvider from 'components/navigation'
import GraphqlClient from '../graphql-client/GraphqlClient'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, registerables } from 'chart.js'
import { MediaQueryProvider } from '@alejosdev/components'

ChartJS.register(ArcElement, Tooltip, Legend, ...registerables)

interface AppWrapperProps {
    children?: React.ReactNode
}

const AppWrapper: React.FC<AppWrapperProps> = () => {
    const { session } = useSession()
    return (
        <ApiClient
            apiUrl={process.env.REACT_APP_API_URL}
            endpoints={endpoints}
            token={session?.auth?.token}
        >
            <GraphqlClient>
                <MediaQueryProvider>
                    <AppRouterProvider
                        layouts={{ authenticated: MainLayout, unauthenticated: EmptyLayout }}
                        routes={routes}
                        authenticated={session.logged}
                        routeAliases={routeAliases}
                    />
                </MediaQueryProvider>
            </GraphqlClient>
        </ApiClient>
    )
}

export default AppWrapper
