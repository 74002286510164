import { gql } from 'urql'

export const ADD_ACTIVITY_CATEGORY_MUTATION = gql`
    mutation createCategory(
        $name: String!
        $color: String
        $description: String
        $icon: String
        $is_rest: Boolean
        $is_work: Boolean
        $is_learning: Boolean
        $is_self_care: Boolean
        $is_exercise: Boolean
        $is_driving: Boolean
        $is_entertainment: Boolean
        $is_feeding: Boolean
        $is_idle: Boolean
        $is_loving: Boolean
        $is_planning: Boolean
        $is_playing: Boolean
        $is_sleep: Boolean
    ) {
        response: activityCategoryCreate(
            name: $name
            color: $color
            description: $description
            icon: $icon
            is_rest: $is_rest
            is_work: $is_work
            is_learning: $is_learning
            is_self_care: $is_self_care
            is_exercise: $is_exercise
            is_driving: $is_driving
            is_entertainment: $is_entertainment
            is_feeding: $is_feeding
            is_idle: $is_idle
            is_loving: $is_loving
            is_planning: $is_planning
            is_playing: $is_playing
            is_sleep: $is_sleep
        ) {
            id
            name
            color
            description
            icon
            is_rest
            is_work
            is_learning
            is_self_care
            is_exercise
            is_driving
            is_entertainment
            is_feeding
            is_idle
            is_loving
            is_planning
            is_playing
            is_sleep
            order_index
        }
    }
`

export const UPDATE_ACTIVITY_CATEGORY_MUTATION = gql`
    mutation updateCategory(
        $id: String!
        $name: String!
        $color: String
        $description: String
        $icon: String
        $is_rest: Boolean
        $is_work: Boolean
        $is_learning: Boolean
        $is_self_care: Boolean
        $is_exercise: Boolean
        $is_driving: Boolean
        $is_entertainment: Boolean
        $is_feeding: Boolean
        $is_idle: Boolean
        $is_loving: Boolean
        $is_planning: Boolean
        $is_playing: Boolean
        $is_sleep: Boolean
    ) {
        response: activityCategoryUpdate(
            id: $id
            name: $name
            color: $color
            description: $description
            icon: $icon
            is_rest: $is_rest
            is_work: $is_work
            is_learning: $is_learning
            is_self_care: $is_self_care
            is_exercise: $is_exercise
            is_driving: $is_driving
            is_entertainment: $is_entertainment
            is_feeding: $is_feeding
            is_idle: $is_idle
            is_loving: $is_loving
            is_planning: $is_planning
            is_playing: $is_playing
            is_sleep: $is_sleep
        ) {
            id
            name
            color
            description
            icon
            is_rest
            is_work
            is_learning
            is_self_care
            is_exercise
            is_driving
            is_entertainment
            is_feeding
            is_idle
            is_loving
            is_planning
            is_playing
            is_sleep
            order_index
        }
    }
`

export const REMOVE_ACTIVITY_CATEGORY_MUTATION = gql`
    mutation updateCategory($id: String!) {
        response: activityCategoryRemove(id: $id)
    }
`
