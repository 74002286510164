import useGetHabitFollowUps from 'graphql/use-get-habit-follow-ups'
import moment from 'moment'
import { HabitScheme } from 'types/schemes/habits.scheme'

const useGetMyWeekHabits = (): [HabitScheme[], boolean, any] => {
    const now = moment()
    const format = 'YYYY-MM-DD'
    const dateFrom = now.clone().startOf('week').format(format)
    const dateTo = now.clone().endOf('week').format(format)

    const [data, loading, { refetch }] = useGetHabitFollowUps({
        lazy: true,
        params: {
            in_dates: `${dateFrom}:${dateTo}`
        }
    })
    return [data, loading, { refetch, dateFrom, dateTo, currentDate: now }]
}

export default useGetMyWeekHabits
