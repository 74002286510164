import { ActivityFollowUpScheme } from 'types/schemes/activities.scheme'
import styles from './activity-item-renderer.module.scss'
import { Icon } from '@alejosdev/components'
import moment from 'moment'
import { useMemo } from 'react'
import { useDateUtils } from 'hooks'
import { getContrastColor } from 'utils'

interface FollowUpItemRendererProps {
    followUp: ActivityFollowUpScheme
    onSelectStart?: (start?: string) => void
    onSelectItem?: (item: ActivityFollowUpScheme) => void
}

const FollowUpItemRenderer: React.FC<FollowUpItemRendererProps> = ({
    followUp,
    onSelectStart,
    onSelectItem
}) => {
    const { activity, started_date, time_spent } = followUp
    const { category } = activity

    const { getMinutesFormatted } = useDateUtils()
    const date = moment(started_date, 'YYYY-MM-DD HH:mm:ss')
    const timeStarted = useMemo(() => date.format('HH:mm'), [date])
    const textColor = getContrastColor(category.color || '')
    const inlineStyles = { color: textColor } as React.CSSProperties

    const timeEnded = useMemo(() => {
        const endDate = moment(date).add(time_spent, 'minutes')
        return endDate.format('HH:mm')
    }, [date, time_spent])
    const elapsedTime = useMemo(
        () => getMinutesFormatted(time_spent),
        [time_spent, getMinutesFormatted]
    )
    return (
        <li className={styles.root} onClick={() => onSelectItem?.(followUp)}>
            <div className={styles.timeShow}>
                <button
                    className={styles.startedDate}
                    onClick={(e) => {
                        e.stopPropagation()
                        onSelectStart?.(timeEnded)
                    }}
                >
                    {timeEnded}
                </button>
                <button className={styles.endDate}>{timeStarted}</button>
            </div>
            <div
                className={styles.iconWrapper}
                style={
                    {
                        '--icon-color': category.color
                    } as React.CSSProperties
                }
            >
                <Icon icon={category?.icon} style={inlineStyles} />
            </div>
            <div
                className={styles.content}
                style={
                    {
                        '--icon-color': category.color
                    } as React.CSSProperties
                }
            >
                <div className={styles.contentWrapper}>
                    <div className={styles.activityInfoWrapper}>
                        <p>
                            {followUp.description.length > 50
                                ? `${followUp.description.slice(0, 50)}...`
                                : followUp.description}
                        </p>
                        <p>
                            {followUp.activity.name} <small>({category.name})</small>
                        </p>
                    </div>
                    <div className={styles.elapsedTimeWrapper}>
                        <Icon icon="clock" />
                        <span>{elapsedTime}</span>
                    </div>
                </div>
            </div>
        </li>
    )
}

/**
 * Discusion with Vincent
 * 1. Clarify the technical path forward.
 * 2. Discuss about the positions.
 * 3.
 *
 *
 * 1. Clarify about the positions and that findmine provides some
 * 2.
 *
 * 1. Confirmed to use The grid, add some insights about the collage.
 * 2.
 */

export default FollowUpItemRenderer
