import { useGetLazy } from '@alejosdev/api-provider'
import { useCallback, useEffect, useRef, useState } from 'react'

type UseSelectFillerConfigType = {
    endpoint: string
}
const useSelectFiller = (
    config?: UseSelectFillerConfigType
): [{ label: string; value: string }[], boolean] => {
    const { endpoint = '' } = config || {}

    const triggered = useRef(false)
    const [data, setData] = useState<{ label: string; value: string }[]>([])
    const [sendRequest, loading] = useGetLazy(endpoint)

    const getData = useCallback(async () => {
        try {
            const { status, data } = await sendRequest()
            if (status === true) {
                setData(data?.map((item: any) => ({ label: item.name, value: item.id })))
            }
        } catch (err) {
            console.log('Error: ', err)
        }
    }, [sendRequest])

    useEffect(() => {
        if (!triggered.current) {
            getData()
            triggered.current = true
        }
    }, [triggered, getData])

    return [data, loading]
}

export default useSelectFiller
