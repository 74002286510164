import React, { useCallback, useEffect, useState } from 'react'
import { readFromCache, readFromSession } from './utils'
import { clearAction, hydrateSessionAction, setAllKeysAction, setKeyAction } from './session.slice'
import { SessionCtxProvider } from './session-ctx'
import { useDispatch, useSelector } from 'react-redux'
import { SessionCTXWrapperProps } from './types'
import {
    clearCacheAction,
    hydrateCacheAction,
    setAllCacheKeysAction,
    setCacheKeyAction
} from './cache.slice'

const SessionCTXWrapper: React.FC<SessionCTXWrapperProps> = ({ children }) => {
    const [hydrated, setHydrated] = useState(false)
    const session = useSelector((state: any) => state.session)
    const dispatch = useDispatch()

    const read = useCallback(() => {
        const session = readFromSession()
        return session
    }, [])

    const readCache = useCallback(() => {
        const fetchedCache = readFromCache()
        return fetchedCache
    }, [])

    const clear = () => {
        dispatch(clearAction())
    }
    const setKey = useCallback((key: string, value: any) => {
        dispatch(setKeyAction({ key, value }))
    }, [])

    const setAllKeys = useCallback((keys: { [k: string]: any }) => {
        dispatch(setAllKeysAction(keys))
    }, [])

    const setCacheKey = useCallback(
        (key: string, value: any) => {
            dispatch(setCacheKeyAction({ key, value }))
        },
        [dispatch]
    )

    const setAllCacheKeys = useCallback(
        (keys: { [k: string]: any }) => {
            dispatch(setAllCacheKeysAction(keys))
        },
        [dispatch]
    )

    const clearCache = useCallback(() => {
        dispatch(clearCacheAction())
    }, [dispatch])

    const hydrate = useCallback(() => {
        const session = read()
        const cache = readCache()
        dispatch(hydrateSessionAction(session))
        dispatch(hydrateCacheAction(cache))
    }, [dispatch])

    const hydrateSession = useCallback(() => {
        if (!hydrated) {
            hydrate()
            setHydrated(true)
        }
    }, [hydrated, hydrate])

    useEffect(() => {
        hydrateSession()
    }, [hydrateSession])

    return (
        <SessionCtxProvider
            value={{
                clear,
                session,
                setAllKeys,
                setKey,
                clearCache,
                setAllCacheKeys,
                setCacheKey
            }}
        >
            {hydrated ? children : <p>Loading session...</p>}
        </SessionCtxProvider>
    )
}

export default SessionCTXWrapper
