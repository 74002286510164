import { ActivityCategoryFormType } from 'types/form-types/activities.form-types'
import useGqlMutation from '../useGqlMutation'
import {
    ADD_ACTIVITY_CATEGORY_MUTATION,
    REMOVE_ACTIVITY_CATEGORY_MUTATION,
    UPDATE_ACTIVITY_CATEGORY_MUTATION
} from './queries'

/**
 * Hook to mutate activity category
 * @returns
 */
const useMutateActivityCategory = () => {
    return useGqlMutation<ActivityCategoryFormType>({
        create: ADD_ACTIVITY_CATEGORY_MUTATION,
        update: UPDATE_ACTIVITY_CATEGORY_MUTATION,
        remove: REMOVE_ACTIVITY_CATEGORY_MUTATION
    })
}

export default useMutateActivityCategory
