import { useContext } from 'react'
import { SessionCtx } from './session-ctx'

const useSession = <T extends any = {}>(): {
    session: T
    setKey: (k: string, v: any) => void
    clear: () => void
    setAllKeys: (keys: any) => void
    setCacheKey: (k: string, v: any) => void
    clearCache: () => void
    setAllCacheKeys: (keys: { [k: string]: any }) => void
} => {
    const { session, ...ctx } = useContext(SessionCtx)
    return { session: session as T, ...ctx }
}

export default useSession
