import { gql } from 'urql'

export const GET_HABIT_FOLLOW_UPS_QUERY = gql`
    query getFollowUps(
        $my_day: Boolean
        $date: String
        $by_habit: Boolean
        $habit_id: String
        $in_dates: String
    ) {
        response: habitsFollowUp(
            my_day: $my_day
            date: $date
            by_habit: $by_habit
            habit_id: $habit_id
            in_dates: $in_dates
        ) {
            id
            name
            description
            start_date
            end_date
            should_keep
            should_avoid
            is_counter
            is_timer
            is_incremental
            is_decremental
            days
            streak
            max_streak
            daily_goal
            timer_goal
            times_goal
            step
            category_id
            measure {
                id
                abbreviation
                name
                icon
                is_distance
                is_liquid
                is_time
            }
            category {
                id
                name
                description
                icon
            }
            follow_ups {
                id
                daily_counter
                daily_goal
                date
                habit_id
                is_accomplished
                is_failed
                story
            }
        }
    }
`
