import { createSlice } from '@reduxjs/toolkit'

export const cacheSlice = createSlice({
    name: 'cache',
    initialState: {},
    reducers: {
        hydrateCacheAction: (_: any, action: any) => {
            return action.payload
        },
        setAllCacheKeysAction: (state: any, action: any) => {
            return {
                ...state,
                ...action.payload
            }
        },
        clearCacheAction: () => {
            return {}
        },
        setCacheKeyAction: (state: any, action: any) => {
            state[action.payload.key] = action.payload.value
        }
    }
})

export const { hydrateCacheAction, setCacheKeyAction, clearCacheAction, setAllCacheKeysAction } =
    cacheSlice.actions

export default cacheSlice.reducer
