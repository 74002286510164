import { useEffect } from 'react'

type KeyboardKeyType = 'Enter' | 'Escape'

const useInputKeyListenerEffect = (
    ref: any,
    callback: () => void,
    key: KeyboardKeyType,
    deps?: any[],
    eventHandler?: 'keyup' | 'keydown' | 'keytyped'
) => {
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            e.key === key && callback()
        }
        if (ref?.current) {
            ref?.current?.addEventListener(eventHandler || 'keyup', handler)
        }

        return () => {
            if (ref?.current) {
                ref?.current?.removeEventListener(eventHandler || 'keyup', handler)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), ref?.current])
}

export default useInputKeyListenerEffect
