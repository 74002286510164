import React from 'react'
import NowIcon from 'assets/icons/now-icon.png'
import PastIcon from 'assets/icons/past-icon.png'
import cs from 'classnames'
import styles from './start-point.module.scss'

const CustomIconButton: React.FC<{
    icon: string
    alt: string
    label: string
    selected?: boolean
    onClick?: () => void
}> = ({ icon, alt, label, selected, onClick }) => {
    return (
        <button
            tabIndex={0}
            className={cs(styles.iconButton, {
                [styles.iconButtonSelected]: selected
            })}
            onClick={onClick}
        >
            <img src={icon} alt={alt} />
            <span>{label}</span>
        </button>
    )
}

interface StartPointProps {
    currentValue?: 'now' | 'past'
    onChange?: (newValue?: 'now' | 'past') => void
}

const StartPoint: React.FC<StartPointProps> = ({ currentValue, onChange }) => {
    return (
        <div className="flex justify-center">
            <CustomIconButton
                icon={NowIcon}
                alt="activity-now"
                label="Right now"
                selected={currentValue === 'now'}
                onClick={() => onChange?.('now')}
            />
            <CustomIconButton
                icon={PastIcon}
                alt="activity-past"
                label="In the past"
                selected={currentValue === 'past'}
                onClick={() => onChange?.('past')}
            />
        </div>
    )
}

export default StartPoint
