import { gql } from 'urql'

export const GET_ACTIVITIES_QUERY = gql`
    query getActivities($id: String) {
        response: activities(id: $id) {
            data {
                id
                category_id
                category {
                    id
                    name
                    icon
                    description
                    color
                }
                name
                description
                spent_time
                order_index
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`
