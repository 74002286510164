import React from 'react'

interface EmptyLayoutProps {
    children?: React.ReactNode
}

const EmptyLayout: React.FC<EmptyLayoutProps> = ({ children }) => {
    return <>{children}</>
}

export default EmptyLayout
