import { createContext } from 'react'
import { SessionProviderType } from './types'

export const SessionCtx = createContext<SessionProviderType>({
    clear: () => null,
    session: {},
    setAllKeys: () => null,
    setKey: () => null,
    clearCache: () => null,
    setAllCacheKeys: () => null,
    setCacheKey: () => null
})

export const SessionCtxProvider = SessionCtx.Provider
export const SessionCtxConsumer = SessionCtx.Consumer
