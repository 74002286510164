const endpoints = {
    'auth.login': 'post:/api/v1/user/login',
    'auth.register': 'post:/api/v1/user/register',
    'activity.list': 'get:/api/v1/activity',
    'activity.save': 'post:/api/v1/activity',
    'activity.view': 'get:/api/v1/activity/{id}',
    'activity.update': 'put:/api/v1/activity/{id}',
    'activity.delete': 'delete:/api/v1/activity/{id}',
    'activity.tracker.save': 'post:/api/v1/activity/followup',
    'activity.tracker.per-day': 'get:/api/v1/activity/day-followups/{date}',
    'activity.tracker.update': 'put:/api/v1/activity/followup/{id}',
    'activity.tracker.remove': 'delete:/api/v1/activity/followup/{id}',
    'activity.categories.list': 'get:/api/v1/activity-categories',
    'activity.categories.save': 'post:/api/v1/activity-categories',
    'activity.categories.update': 'put:/api/v1/activity-categories/{id}',
    'activity.categories.delete': 'delete:/api/v1/activity-categories/{id}',
    'activity.sleep-track.add': 'post:/api/v1/sleep-tracker/register',
    'courses.list': 'get:/api/v1/courses',
    'courses.update': 'put:/api/v1/courses/{id}',
    'courses.delete': 'delete:/api/v1/courses/{id}',
    'courses.save': 'post:/api/v1/courses',
    'courses.followUps.save': 'post:/api/v1/course-followup',
    'courses.followUps.list': 'get:/api/v1/course-followup/{course}',
    'courses.followUps.update': 'put:/api/v1/course-followup/{id}',
    'courses.followUps.remove': 'delete:/api/v1/course-followup/{id}',
    'courses.view': 'get:/api/v1/courses/{id}',
    'habits.list': 'get:/api/v1/habits',
    'habits.save': 'post:/api/v1/habit',
    'habits.update': 'put:/api/v1/habit/{id}',
    'habits.delete': 'delete:/api/v1/habit/{id}',
    'habits.myDay': 'get:/api/v1/follow-ups/habit/my-day/{date}',
    'habits.inDates': 'get:/api/v1/follow-ups/habit/in-dates/{dateFrom}/{dateTo}',
    'habits.currentWeek': 'get:/api/v1/follow-ups/habit/in-dates/{dateFrom}/{dateTo}',
    'habits.categories.save': 'post:/api/v1/habit-category',
    'habits.categories.update': 'put:/api/v1/habit-category/{id}',
    'habits.categories.delete': 'delete:/api/v1/habit-category/{id}',
    'habits.categories.list': 'get:/api/v1/habit-categories',
    'habits.followUps.save': 'post:/api/v1/follow-ups/habit/add',
    'habits.followUps.update': 'put:/api/v1/follow-ups/habit/{id}',
    'general.measures.list': 'get:/api/v1/settings/measures',
    'general.measures.save': 'post:/api/v1/settings/measure',
    'general.measures.update': 'put:/api/v1/settings/measure/{id}',
    'general.measures.delete': 'delete:/api/v1/settings/measure/{id}',
    'settings.wallets.frequencies.save': 'post:/api/v1/settings/wallet-frequency',
    'settings.wallets.frequencies.list': 'get:/api/v1/settings/wallet-frequency',
    'settings.wallets.frequencies.update': 'put:/api/v1/settings/wallet-frequency/{id}',
    'settings.wallets.frequencies.delete': 'delete:/api/v1/settings/wallet-frequency/{id}',
    'settings.todos.frequencies.list': '/api/v1/todo/frequency',
    'settings.todos.frequencies.save': '/api/v1/todo/frequency',
    'settings.todos.frequencies.update': '/api/v1/todo/frequency/{id}',
    'settings.todos.frequencies.delete': '/api/v1/todo/frequency/{id}',
    'wallet.expenses-categories.list': 'get:/api/v1/wallet-category',
    'wallet.expenses-categories.save': 'post:/api/v1/wallet-category',
    'wallet.expenses-categories.update': 'put:/api/v1/wallet-category/{id}',
    'wallet.expenses-categories.delete': 'delete:/api/v1/wallet-category/{id}',
    'wallet.save': 'post:/api/v1/wallet',
    'wallet.list': 'get:/api/v1/wallet',
    'wallet.update': 'put:/api/v1/wallet/{id}',
    'wallet.delete': 'delete:/api/v1/wallet/{id}',
    'wallet.budgets.list': 'get:/api/v1/wallet/budget',
    'wallet.budgets.save': 'post:/api/v1/wallet/budget',
    'wallet.budgets.update': 'put:/api/v1/wallet/budget/{id}',
    'wallet.budgets.delete': 'delete:/api/v1/wallet/budget/{id}',
    'wallet.budgets.close': 'post:/api/v1/wallet/budget/close/{id}',
    'wallet.periods.list': 'get:/api/v1/wallet/period',
    'wallet.periods.save': 'post:/api/v1/wallet/period',
    'wallet.periods.update': 'put:/api/v1/wallet/period/{id}',
    'wallet.periods.delete': 'delete:/api/v1/wallet/period/{id}',
    'wallet.transactions.list': 'get:/api/v1/wallet/expense/{dateFrom}/{dateTo}',
    'wallet.transactions.save': 'post:/api/v1/wallet/expense',
    'wallet.transactions.update': 'put:/api/v1/wallet/expense/{id}',
    'wallet.transactions.delete': 'delete:/api/v1/wallet/expense/{id}',
    'wallet.scheduled.expenses.save': 'post:/api/v1/wallet/expense-scheduled',
    'wallet.scheduled.expenses.list': 'get:/api/v1/wallet/expense-scheduled/{dateFrom}/{dateTo}',
    'wallet.scheduled.expenses.update': 'put:/api/wallet/expense-scheduled/update/{id}',
    'wallet.scheduled.expenses.delete': '/api/v1/wallet/expense-scheduled/remove/{id}',
    'todos.categories.list': 'get:/api/v1/todo/category',
    'todos.categories.save': 'post:/api/v1/todo/category',
    'todos.categories.update': 'put:/api/v1/todo/category/{id}',
    'todos.categories.delete': 'delete:/api/v1/todo/category/{id}',
    'todos.list': 'get:/api/v1/todo',
    'todos.save': 'post:/api/v1/todo',
    'todos.update': 'put:/api/v1/todo/{id}',
    'todos.delete': 'delete:/api/v1/todo/{id}',
    'todos.delete.bulk': 'post:/api/v1/todo/remove-bulk',
    'todos-list.list': 'get:/api/v1/todo-list',
    'todos-list.save': 'post:/api/v1/todo-list',
    'todos-list.update': 'put:/api/v1/todo-list/{id}',
    'todos-list.delete': 'delete:/api/v1/todo-list/{id}',
    'todos.toggle': 'patch:/api/v1/todo/toggle/{id}',
    'todos.subtask.add': 'post:/api/v1/todo/add-subtask',
    'todos.subtask.update': 'put:/api/v1/todo/update-subtask/{task}',
    'todos.subtask.remove': 'delete:/api/v1/todo/remove-subtask/{task}',
    'todos.subtask.toggle': 'patch:/api/v1/todo/toggle-subtask/{task}',
    nowhere: '/'
}

export default endpoints
