import { gql } from 'urql'

export const GET_ACTIVITIES_FOLLOW_UPS_QUERY = gql`
    query getFollowUps($date: String) {
        response: followUps(for_date: $date) {
            data {
                id
                date
                time_spent
                description
                activity_id
                activity {
                    id
                    category_id
                    category {
                        id
                        name
                        color
                        description
                        icon
                        is_rest
                        is_work
                        is_learning
                        is_self_care
                        is_exercise
                        is_driving
                        is_entertainment
                        is_feeding
                        is_idle
                        is_loving
                        is_planning
                        is_playing
                        is_sleep
                        order_index
                    }
                    name
                    description
                    spent_time
                    order_index
                }
                started_date
            }
            total
            per_page
            current_page
            from
            to
            last_page
            has_more_pages
        }
    }
`
