import React, { useMemo } from 'react'
import { useRouterCtx, useRoutes } from './hooks'
import { Routes, Route, Navigate } from 'react-router-dom'
import RouteRenderer from './RouteRenderer'

/**
 * Component to render the application routes.
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 */
const AppRouter: React.FC = () => {
    const generatedRoutes = useRoutes()
    const { layouts, authenticated } = useRouterCtx()

    const Layout: any = useMemo(() => {
        if (!layouts) return null
        return authenticated ? layouts.authenticated : layouts.unauthenticated
    }, [authenticated, layouts])

    const renderer = useMemo(() => {
        return (
            <Routes>
                {generatedRoutes?.map((route, key) => (
                    <Route
                        key={key}
                        index
                        path={route.path}
                        element={
                            <RouteRenderer Component={route.Component} layout={route.layout} />
                        }
                    />
                ))}
                <Route path="*" element={<Navigate to={'/'} replace />} />
            </Routes>
        )
    }, [generatedRoutes])

    return Layout ? <Layout>{renderer}</Layout> : renderer
}

export default AppRouter
