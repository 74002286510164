import React, { useMemo, useState } from 'react'
import { Icon, TextField } from '@alejosdev/components'
import useGetActivities from 'graphql/use-get-activities'
import { ActivityScheme } from 'types/schemes/activities.scheme'
import ActivityPickerItem from './ActivityPickerItem'
import styles from './activity-picker.module.scss'
interface ActivityPickerProps {
    onSelect?: (activity: ActivityScheme) => void
    selectedActivity?: ActivityScheme | null
}

const ActivityPicker: React.FC<ActivityPickerProps> = ({ selectedActivity, onSelect }) => {
    const [activities, loading] = useGetActivities()
    const [query, setQuery] = useState<string>()

    const [activitiesToRender, rest] = useMemo(() => {
        let base = [...activities]
        if (query) {
            base = activities.filter((item) => {
                const exp = new RegExp(`.*${query.toLowerCase()}.*`, 'g')
                return item.name.toLowerCase().match(exp)
            })
        }
        const result = [...base].splice(0, 5)
        const rest = [...base].splice(6)
        return [result, rest]
    }, [activities, query])

    return (
        <div className={styles.root}>
            {selectedActivity && (
                <div>
                    <div>
                        <Icon icon={selectedActivity?.category?.icon} />
                    </div>
                    <p>{selectedActivity.name}</p>
                </div>
            )}
            <TextField
                placeholder="Search for an activity"
                autoFocus
                value={query}
                onChange={({ target: { value } }) => setQuery(value)}
            />
            {loading && <p>Loading activities...</p>}
            <ul className="mt-4">
                {activitiesToRender?.map((activity) => (
                    <ActivityPickerItem
                        key={activity.id}
                        activity={activity}
                        onSelect={onSelect}
                        selectedActivity={selectedActivity}
                    />
                ))}
            </ul>
            {rest?.length > 0 && <p className="text-center mb-4"> and {rest.length} more</p>}
        </div>
    )
}

export default ActivityPicker
