import { ActivityFollowUpFormType } from 'types/form-types/activities.form-types'
import useGqlMutation from '../useGqlMutation'
import {
    ACTIVITY_FOLLOW_UP_ADD_MUTATION,
    ACTIVITY_FOLLOW_UP_UPDATE_MUTATION,
    ACTIVITY_FOLLOW_UP_REMOVE_MUTATION
} from './queries'

/**
 * Hook to mutate activity follow ups
 * @returns
 */
const useMutateActivityFollowUp = () => {
    return useGqlMutation<ActivityFollowUpFormType>({
        create: ACTIVITY_FOLLOW_UP_ADD_MUTATION,
        update: ACTIVITY_FOLLOW_UP_UPDATE_MUTATION,
        remove: ACTIVITY_FOLLOW_UP_REMOVE_MUTATION
    })
}

export default useMutateActivityFollowUp
