import { useContext, useMemo } from 'react'
import { AppRouterCTX } from './ctx'

/**
 * Hook to access the application context
 * @returns AppRouterCTX
 */
export const useRouterCtx = () => {
    return useContext(AppRouterCTX)
}

/**
 * Hook to build the app routes depending on the user authentication.
 */
export const useRoutes = (): {
    path: string
    Component: React.ComponentType
    layout?: string
}[] => {
    const { routes, authenticated } = useRouterCtx()
    const mappedRoutes = useMemo(() => {
        const selectedRoutes = authenticated ? routes.authenticated : routes.unauthenticated
        return Object.keys(selectedRoutes).reduce((items, currentItem) => {
            const currentConfig: any = selectedRoutes[currentItem]
            if (currentConfig.layout) {
                items.push({
                    path: currentItem,
                    Component: currentConfig.component,
                    layout: currentConfig.layout
                })
            } else {
                items.push({
                    path: currentItem,
                    Component: selectedRoutes[currentItem]
                })
            }

            return items
        }, [] as { path: string; Component: any; layout?: string }[])
    }, [authenticated, routes])
    return mappedRoutes
}
