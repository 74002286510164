import React from 'react'
import { SelectField, InputBaseProps } from '@alejosdev/components'
import { useSelectFiller } from 'hooks'

const ActivityCategorySelect: React.FC<InputBaseProps<HTMLSelectElement>> = ({
    label = 'Activity category',
    ...props
}) => {
    const [data] = useSelectFiller({ endpoint: 'activity.categories.list' })

    return <SelectField label={label} options={data} placeholder="Select a category" {...props} />
}

export default ActivityCategorySelect
