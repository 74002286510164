import useGetActivitiesFollowUps from 'graphql/use-get-activities-follow-ups'
import { useMemo, useState } from 'react'
import moment from 'moment'
import DayListRenderer from './DayListRenderer'
import { ActivityFollowUpScheme } from '../../../types/schemes/activities.scheme'
import { FollowUpsListType } from './types'
import ActivityWizard from '../activity-wizard'
import FollowUpView from '../follow-up-view'

interface MyDailyActivitiesProps {
    onStart?: () => void
}

const MyDailyActivities: React.FC<MyDailyActivitiesProps> = () => {
    const [aboutToStart, setAboutToStart] = useState(false)
    const [startingDate, setStartingDate] = useState<string | null>()
    const [defaultDuration, setDefaultDuration] = useState<number | undefined>()
    const [selectedToView, setSelectedToView] = useState<ActivityFollowUpScheme | null | undefined>(
        null
    )
    const [defaultType, setDefaultType] = useState<'past' | 'now' | null>()
    const date = useMemo(() => moment().format('YYYY-MM-DD'), [])
    const [followUps, loading, { refetch }] = useGetActivitiesFollowUps({ variables: { date } })

    const handleSaved = () => {
        refetch()
        setStartingDate(null)
        setDefaultDuration(undefined)
        setAboutToStart(false)
        setSelectedToView(null)
    }

    const orderedFollowUps = useMemo(() => {
        if (!followUps || !Array.isArray(followUps)) return []
        let result = [...followUps] as FollowUpsListType[]
        const finalOutput: any[] = []
        for (let index = 0; index < result.length; index++) {
            const recentEvent = result[index] as ActivityFollowUpScheme
            const olderEvent = result[index + 1] as ActivityFollowUpScheme
            if (!olderEvent) {
                finalOutput.push(recentEvent)
                continue
            }
            const format = 'YYYY-MM-DD HH:mm:ss'
            const olderEnd = moment(olderEvent.started_date, format).add(
                olderEvent.time_spent,
                'minutes'
            )
            const recentStart = moment(recentEvent.started_date, format)
            const duration = moment.duration(recentStart.diff(olderEnd))
            const difference = duration.asMinutes()
            finalOutput.push(recentEvent)
            if (difference > 0) {
                finalOutput.push({
                    isEmpty: true,
                    timeFrom: olderEnd.format(format),
                    timeTo: moment(olderEnd).add(difference, 'minutes').format(format),
                    time: difference
                })
            }
        }
        return finalOutput
    }, [followUps])

    const handleCloseView = () => {
        setSelectedToView(null)
    }

    return (
        <>
            <DayListRenderer
                items={orderedFollowUps}
                loading={loading}
                onStart={() => {
                    setAboutToStart(true)
                    setDefaultDuration(0)
                    setStartingDate(null)
                }}
                onSelectItem={(item: ActivityFollowUpScheme) => setSelectedToView(item)}
                onSelectStart={(start?: string, duration?: number, isInPast?: 'past' | 'now') => {
                    const starting = (
                        (start || '')?.length > 5
                            ? moment(start, 'YYYY-MM-DD HH:mm:ss')
                            : moment(start, 'HH:mm')
                    ).format('HH:mm')
                    setStartingDate(starting)
                    setDefaultDuration(duration)
                    setAboutToStart(true)
                    setDefaultType(isInPast)
                }}
            />
            {aboutToStart && (
                <ActivityWizard
                    onSaved={handleSaved}
                    startingDate={startingDate}
                    defaultDuration={defaultDuration}
                    onStarted={() => setAboutToStart(false)}
                    defaultType={defaultType}
                    onClose={() => {
                        setAboutToStart(false)
                        setDefaultDuration(undefined)
                        setStartingDate(null)
                        setDefaultType(null)
                    }}
                />
            )}
            {selectedToView && (
                <FollowUpView
                    onSaved={handleSaved}
                    followUp={selectedToView}
                    onClose={handleCloseView}
                    onDeleted={handleSaved}
                />
            )}
        </>
    )
}

export default MyDailyActivities
