import { useEffect } from 'react'

type KeyboardKeyType = 'Enter'

const useKeyListenerEffect = (callback: () => void, key: KeyboardKeyType, deps: [any]) => {
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            e.key === key && callback()
        }
        window.addEventListener('keyup', handler)
        return () => {
            window.removeEventListener('keyup', handler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps])
}

export default useKeyListenerEffect
