import React from 'react'
import styles from './empty-activity-follow-up.module.scss'
import { FollowUpEmptySpaceType } from './types'
import { useDateUtils } from 'hooks'
import { IconButton } from '@alejosdev/components'

interface EmptyActivityFollowUpProps {
    item: FollowUpEmptySpaceType
    onSelectStart?: (start?: string, duration?: number, isInPast?: 'past' | 'now') => void
}

const EmptyActivityFollowUp: React.FC<EmptyActivityFollowUpProps> = ({ item, onSelectStart }) => {
    const { getMinutesFormatted } = useDateUtils()
    const handleSelect = () => {
        onSelectStart?.(item.timeFrom, item.time, 'past')
    }
    return (
        <li className={styles.root} onClick={handleSelect}>
            <div className={styles.leftSpace}></div>
            <div className={styles.content}>
                <IconButton className={styles.icon} icon="add" variant="info" size="sm" />
                <span>{getMinutesFormatted(item.time)} free</span>
            </div>
        </li>
    )
}

export default EmptyActivityFollowUp
