import styles from './activities-my-day-chart.module.scss'
import { Pie, Bar } from 'react-chartjs-2'
import useDailyDataProvider from './useDailyDataProvider'
import React from 'react'

interface ActivitiesMyDayChartProps {}

/**
 * @todo: Fix a re-render issue
 * @returns
 */
const ActivitiesMyDayChart: React.FC<ActivitiesMyDayChartProps> = () => {
    const { pie, bars } = useDailyDataProvider()
    return (
        <div className={styles.root}>
            <h3 className={styles.title}>My day</h3>
            <div className="flex flex-col xl:flex-row">
                <div className="flex-1 mb-8">
                    <div className={styles.chartWrapper}>
                        <Pie
                            data={pie}
                            className={styles.chart}
                            height={300}
                            options={{
                                maintainAspectRatio: false,
                                plugins: {
                                    tooltip: {
                                        callbacks: {
                                            label: function ({ parsed }: { parsed: number }) {
                                                if (parsed < 60) {
                                                    return `${parsed}mins`
                                                }
                                                const hours = Math.floor(parsed / 60)
                                                const minutes = parsed % 60
                                                return `${hours}h ${minutes}mins`
                                            }
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex-1">
                    <Bar
                        data={bars}
                        options={{
                            plugins: {
                                legend: {},
                                tooltip: {
                                    callbacks: {
                                        label: ({ raw }: any) => {
                                            if (raw < 60) {
                                                return `${raw}mins`
                                            }
                                            const hours = Math.floor(raw / 60)
                                            const minutes = raw % 60
                                            return `${hours}h ${minutes}mins`
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default React.memo(ActivitiesMyDayChart)
