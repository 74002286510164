import React from 'react'
import { RoutesConfigType, RoutingConfigType } from './types'

export const AppRouterCTX = React.createContext<{
    routes: RoutesConfigType
    authenticated?: boolean
    goTo: (path: string, config?: RoutingConfigType) => void
    redirectTo: (path: string, config?: RoutingConfigType) => void
    goBack: () => void
    layouts?: {
        [k: string]: React.ComponentType
    }
}>({
    routes: {
        authenticated: {},
        unauthenticated: {}
    },
    goTo: () => {},
    redirectTo: () => {},
    goBack: () => {}
})

export const AppRouterCTXProvider = AppRouterCTX.Provider
export const AppRouterCTXConsumer = AppRouterCTX.Consumer
