import React from 'react'
import styles from './main-layout.module.scss'
import { Menu, ToolBar } from 'components'
import { ActivityInProgress } from 'components/activities'
import { useSession } from 'hooks'

interface MainLayoutProps {
    children: React.ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { session } = useSession()
    return (
        <>
            <Menu />
            <ToolBar />
            <div className={styles.content}>{children}</div>
            {session?.activities?.inProgress && <ActivityInProgress />}
        </>
    )
}

export default MainLayout
