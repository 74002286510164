import { NumberField, OptionsField, TimeField } from '@alejosdev/components'
import { useDateUtils } from 'hooks'
import usePrevProps from 'hooks/use-prev-props'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'

interface DurationAndTimeProps {
    started?: string
    type?: 'now' | 'past'
    duration?: number
    setStarted?: (started?: string) => void
    setDuration?: (duration?: number) => void
    enableDuration?: 'yes' | 'no'
    setEnableDuration?: (val?: 'yes' | 'no') => void
}

const DurationAndTime: React.FC<DurationAndTimeProps> = ({
    setDuration,
    setStarted,
    duration,
    started,
    enableDuration,
    setEnableDuration,
    type
}) => {
    const { getDateDiff } = useDateUtils()
    const [endedAt, setEndedAt] = useState(
        moment(started, 'HH:mm')
            .add(duration || 60, 'minutes')
            .format('HH:mm')
    )
    const prevProps = usePrevProps({
        started,
        endedAt
    })

    const handleChangeDuration = useCallback(
        ({ target: { value } }: any) => {
            setEnableDuration?.(value)
        },
        [setEnableDuration]
    )

    const updateDuration = useCallback(() => {
        const currDate = moment()
        const format = 'YYYY-MM-DD'
        const dateFrom = `${currDate.format(format)} ${started}:00`
        const dateTo = `${currDate.format(format)} ${endedAt}:00`
        const diff = getDateDiff({
            startDate: dateFrom,
            format: 'YYYY-MM-DD HH:mm:ss',
            endDate: dateTo,
            diffType: 'minute'
        })
        setDuration?.(parseInt(`${diff}`, 10))
    }, [started, getDateDiff, endedAt, setDuration])

    const handleChangeEnd = useCallback(({ target }: any) => {
        setEndedAt(target?.value)
    }, [])

    useEffect(() => {
        const shouldUpdate =
            (type === 'past' && !duration) ||
            prevProps.started !== started ||
            prevProps.endedAt !== endedAt
        if (shouldUpdate) {
            updateDuration()
        }
    }, [type, duration, updateDuration, prevProps.started, prevProps.endedAt, started, endedAt])
    return (
        <div className="flex justify-center flex-col items-center">
            <p>{started}</p>
            <div className="flex justify-center">
                <TimeField
                    label="Started at"
                    autoFocus
                    value={started}
                    onChange={({ target }: any) => setStarted?.(target?.value as any)}
                />
            </div>
            {type === 'past' && (
                <div className="flex justify-center">
                    <TimeField label="Ended at" value={endedAt} onChange={handleChangeEnd} />
                </div>
            )}

            <div className="w-2/3 mb-12 mt-4">
                {type === 'now' && (
                    <OptionsField
                        label="Do you know the duration?"
                        // horizontal
                        name="duration"
                        value={enableDuration}
                        onChange={handleChangeDuration}
                        options={[
                            { label: 'Yes', value: 'yes' },
                            { label: 'No', value: 'no' }
                        ]}
                    />
                )}
                {(enableDuration === 'yes' || type === 'now') && (
                    <NumberField
                        label={type === 'past' ? 'Duration' : undefined}
                        value={duration}
                        onChange={(e: any) => setDuration?.(e?.target?.value)}
                    />
                )}
            </div>
            {type === 'past' && <div></div>}
        </div>
    )
}
export default DurationAndTime
