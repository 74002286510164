import { gql } from 'urql'

export const ACTIVITY_FOLLOW_UP_ADD_MUTATION = gql`
    mutation add(
        $activity_id: String!
        $description: String
        $time_spent: Int
        $date: String!
        $started_date: String!
    ) {
        response: activityFollowUpAdd(
            activity_id: $activity_id
            description: $description
            time_spent: $time_spent
            date: $date
            started_date: $started_date
        ) {
            date
            time_spent
            description
            activity_id
            activity {
                id
                category_id
                category {
                    id
                    name
                    icon
                    color
                }
                name
                description
                spent_time
                order_index
            }
            started_date
        }
    }
`

export const ACTIVITY_FOLLOW_UP_UPDATE_MUTATION = gql`
    mutation update(
        $id: String!
        $activity_id: String
        $description: String
        $time_spent: Int!
        $date: String!
        $started_date: String!
    ) {
        response: followUpUpdate(
            id: $id
            activity_id: $activity_id
            description: $description
            time_spent: $time_spent
            date: $date
            started_date: $started_date
        ) {
            id
            date
            time_spent
            description
            activity_id
            activity {
                id
                category_id
                category {
                    id
                    name
                    icon
                    color
                }
                name
                description
                spent_time
                order_index
            }
            started_date
        }
    }
`

export const ACTIVITY_FOLLOW_UP_REMOVE_MUTATION = gql`
    mutation remove($id: String!) {
        response: followUpRemove(id: $id)
    }
`
