import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {},
    reducers: {
        hydrateSessionAction: (_: any, action: any) => {
            return action.payload
        },
        setAllKeysAction: (state: any, action: any) => {
            return {
                ...state,
                ...action.payload
            }
        },
        clearAction: () => {
            return {}
        },
        setKeyAction: (state: any, action: any) => {
            state[action.payload.key] = action.payload.value
        }
    }
})

export const { clearAction, hydrateSessionAction, setAllKeysAction, setKeyAction } =
    sessionSlice.actions

export default sessionSlice.reducer
