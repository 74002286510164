import BaseChart from './BaseChart'
import moment from 'moment'

import { useCallback, useMemo } from 'react'
import { ActivityFollowUpScheme } from 'types/schemes/activities.scheme'
import useGetActivitiesFollowUps from 'graphql/use-get-activities-follow-ups'

interface WorkChartProps {}

const WorkChart: React.FC<WorkChartProps> = () => {
    const currentDate = moment().format('YYYY-MM-DD')
    const [followUps] = useGetActivitiesFollowUps({
        variables: { date: currentDate }
    })

    const workActivities: ActivityFollowUpScheme[] = followUps?.filter(
        (followUp: ActivityFollowUpScheme) => {
            return followUp?.activity?.category?.is_work
        },
        [followUps]
    )
    const workGoal = 8 * 60 // Six hours

    const processedData = useMemo(() => {
        const labels: string[] = ['Work']
        const data: number[] = []
        const colors: string[] = []
        const [first] = workActivities || []
        const defColor = first?.activity?.category?.color || '#d44949'
        const workedTime = workActivities?.reduce(
            (total: number, followUp: ActivityFollowUpScheme) => {
                total += followUp.time_spent
                return total
            },
            0
        )
        data.push(workedTime)
        colors.push(defColor)
        return { labels, data, colors }
    }, [workActivities])

    const usedTime = useMemo(() => {
        return processedData?.data?.reduce((total: number, time: number) => {
            return total + time
        }, 0)
    }, [processedData])

    const timeLeftToWork = useMemo(() => {
        return workGoal - usedTime
    }, [workGoal, usedTime])

    const data = useMemo(() => {
        return {
            labels: [...processedData.labels, 'Time left'],
            data: [...processedData.data, timeLeftToWork < 0 ? 0 : timeLeftToWork],
            backgroundColor: [...processedData.colors, '#ccc']
        }
    }, [processedData, timeLeftToWork])

    const formattedTime = useCallback((time: number) => {
        if (time < 60) {
            return `${time}m`
        }
        const hours = Math.floor(time / 60)
        const minutes = time % 60
        return `${hours}h ${minutes}m`
    }, [])

    return (
        <BaseChart
            title="Work"
            value={`${formattedTime(usedTime)}`}
            data={{
                labels: data.labels,
                datasets: [
                    {
                        label: 'Work chart',
                        data: data.data,
                        backgroundColor: data.backgroundColor
                    }
                ]
            }}
            processLabel={(data: { parsed: number }) => {
                const { parsed } = data
                if (parsed < 60) {
                    return `${parsed}mins`
                }
                const hours = Math.floor(parsed / 60)
                const minutes = parsed % 60
                return `${hours}h ${minutes}mins`
            }}
        />
    )
}

export default WorkChart
