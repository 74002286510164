import { DetailView, IconButton } from '@alejosdev/components'
import { useDateUtils } from 'hooks'
import moment from 'moment'
import React, { useMemo } from 'react'
import { ActivityScheme } from 'types/schemes/activities.scheme'

interface SummaryProps {
    type?: string
    activity?: ActivityScheme | null
    duration?: number
    startedDate?: string
    date?: string
    description?: string
    setStarted?: (started?: string) => void
    setDuration?: (duration?: number) => void
    setActivity?: (activity?: ActivityScheme) => void
}

const Summary: React.FC<SummaryProps> = ({
    type,
    activity,
    duration,
    startedDate,
    description,
    date
}) => {
    const { getMinutesFormatted } = useDateUtils()
    const durationFormatted = useMemo(
        () => getMinutesFormatted(duration || 0),
        [duration, getMinutesFormatted]
    )
    const endDate = useMemo(() => {
        if (duration) {
            const composedDate = `${date} ${startedDate}:00`
            return moment(composedDate, 'YYYY-MM-DD HH:mm:ss')
                .add(duration, 'minutes')
                .format('HH:mm')
        }
        return ''
    }, [duration, startedDate, date])
    return (
        <div>
            <DetailView
                details={[
                    { title: 'When', description: type, icon: 'clock' },
                    {
                        title: 'Activity',
                        description: activity?.name,
                        icon: activity?.category.icon
                    },
                    {
                        title: 'Description',
                        icon: 'clipboard',
                        description
                    },
                    { title: 'Started at', description: startedDate, icon: 'clock' },
                    {
                        title: type === 'now' ? 'Estimated time' : 'Duration',
                        description: duration && duration > 0 ? `${durationFormatted}` : 'Unknown',
                        icon: 'stopwatch-20'
                    },
                    type === 'past'
                        ? {
                              icon: 'clock',
                              title: 'Ends at',
                              description: endDate as any
                          }
                        : null
                ]}
            >
                <div className="flex justify-center">
                    <IconButton variant="secondary" icon="pen" />
                </div>
            </DetailView>
        </div>
    )
}

export default Summary
