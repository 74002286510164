import React from 'react'

/**
 * Component to render the application route
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
const RouteRenderer: React.FC<{ Component: React.ComponentType; layout?: string }> = ({
    Component
}) => {
    return (
        <React.Suspense>
            <Component />
        </React.Suspense>
    )
}

export default RouteRenderer
