import React, { useCallback } from 'react'
import { ActivityScheme } from 'types/schemes/activities.scheme'
import { CheckBoxField } from '@alejosdev/components'
import styles from './activity-picker-item.module.scss'

interface ActivityPickerItemProps {
    activity: ActivityScheme
    onSelect?: (activity: ActivityScheme) => void
    selectedActivity?: ActivityScheme | null
}

const ActivityPickerItem: React.FC<ActivityPickerItemProps> = ({
    activity,
    onSelect,
    selectedActivity
}) => {
    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent, activity: ActivityScheme) => {
            if (['Space', 'Enter'].includes(e.code)) {
                e.preventDefault()
                onSelect?.(activity)
            }
        },
        [onSelect]
    )
    return (
        <li
            tabIndex={0}
            key={activity.id}
            className={styles.root}
            onKeyDown={(e) => handleKeyDown(e, activity)}
            onClick={() => onSelect?.(activity)}
        >
            <CheckBoxField
                checked={selectedActivity?.id === activity.id}
                className={styles.control}
            />
            <span className={styles.activityName}>{activity.name}</span>
        </li>
    )
}

export default ActivityPickerItem
