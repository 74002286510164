import React from 'react'
import { SelectField, InputBaseProps } from '@alejosdev/components'
import { useSelectFiller } from 'hooks'

const ActivitySelect: React.FC<InputBaseProps<HTMLSelectElement>> = ({
    label = 'Activity',
    ...props
}) => {
    const [data] = useSelectFiller({ endpoint: 'activity.list' })

    return <SelectField label={label} options={data} placeholder="Select an activity" {...props} />
}

export default ActivitySelect
