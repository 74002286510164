import { Doughnut } from 'react-chartjs-2'
import { Pill } from '@alejosdev/components'
import styles from './base-chart.module.scss'

interface BaseChartProps {
    children?: React.ReactNode
    data?: {
        labels: string[]
        datasets: {
            label: string
            data: number[]
            backgroundColor?: string[]
            borderColor?: string[]
            borderWidth?: number
        }[]
    }
    processLabel?: (data: any) => any
    title?: string
    value?: string
}

const BaseChart: React.FC<BaseChartProps> = ({ data, processLabel, title, value }) => {
    return (
        <div className={styles.root}>
            <Doughnut
                data={data || { labels: [], datasets: [] }}
                options={{
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: processLabel
                            }
                        }
                    }
                }}
            />
            <div>
                <Pill label={title} value={value} />
            </div>
        </div>
    )
}

export default BaseChart
