import {
    DetailView,
    IconButton,
    NumberField,
    TextAreaField,
    TimeField
} from '@alejosdev/components'
import { ConfirmDialog, Dialog } from 'components/dialogs'
import { ActivitySelect } from 'components/form'
import useMutateActivityFollowUp from 'graphql/use-mutate-activity-follow-up'
import { useDateUtils } from 'hooks'
import moment from 'moment'
import React, { useState } from 'react'
import { ActivityFollowUpScheme } from 'types/schemes/activities.scheme'

interface FollowUpViewProps {
    followUp: ActivityFollowUpScheme
    onSaved?: () => void
    onClose?: () => void
    onDeleted?: () => void
}

const FollowUpView: React.FC<FollowUpViewProps> = ({ followUp, onSaved, onClose, onDeleted }) => {
    const { activity } = followUp
    const [enableEdit, setEnableEdit] = useState(false)
    const { update, remove, loading } = useMutateActivityFollowUp()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const startedTime = moment(followUp.started_date, 'YYYY-MM-DD HH:mm:ss')
    type TypeForm = {
        activityId: string
        duration: number
        time: string
        description: string
    }
    const [form, setForm] = useState<TypeForm>({
        activityId: activity.id,
        duration: followUp.time_spent,
        time: startedTime.format('HH:mm'),
        description: followUp.description
    })
    const { getMinutesFormatted } = useDateUtils()
    const started = startedTime.format('hh:mm a')
    const ended = startedTime.clone().add(followUp.time_spent, 'minutes').format('hh:mm a')
    const changeForm = (key: string, value: any) => {
        setForm({
            ...form,
            [key]: value
        })
    }
    const handleUpdate = async () => {
        try {
            await update({
                activity_id: form.activityId,
                id: followUp.id,
                date: followUp.date,
                time_spent: form.duration,
                started_date: `${followUp.date} ${form.time}:00`,
                description: form.description
            })
            onSaved?.()
        } catch (err) {
            console.log('Err: ', err)
        }
    }
    const handleDelete = async () => {
        try {
            const response = await remove(followUp.id)
            if (response === true) onDeleted?.()
        } catch (err) {
            console.log('Error: ', err)
        }
    }
    return (
        <Dialog open title={activity.name} lock={loading} disableFooter onClose={onClose}>
            {!enableEdit && (
                <DetailView
                    details={[
                        {
                            title: 'Category',
                            icon: activity.category.icon,
                            description: activity.category.name
                        },
                        {
                            title: 'Time',
                            icon: 'clock',
                            description: getMinutesFormatted(followUp.time_spent)
                        },
                        {
                            title: 'Started',
                            icon: 'calendar',
                            description: started
                        },
                        {
                            title: 'Description',
                            icon: 'book',
                            description: followUp.description
                        },
                        {
                            title: 'Ended',
                            icon: 'calendar',
                            description: ended
                        }
                    ]}
                />
            )}
            {enableEdit && (
                <div className="flex justify-center">
                    <div className="mb-4 w-2/3 self-center">
                        <ActivitySelect
                            value={form.activityId}
                            onChange={({ target: { value } }) => changeForm('activityId', value)}
                        />
                        <div className="flex justify-center">
                            <TimeField
                                label="Started at"
                                value={form.time}
                                onChange={({ target: { value } }: any) => changeForm('time', value)}
                            />
                        </div>
                        <TextAreaField
                            label="Description"
                            max={1000}
                            value={form.description}
                            onChange={({ target: { value } }: any) =>
                                changeForm('description', value)
                            }
                        />
                        <NumberField
                            label="Duration"
                            value={form.duration}
                            onChange={({ target: { value } }) => changeForm('duration', value)}
                        />
                    </div>
                </div>
            )}
            {confirmDelete && (
                <ConfirmDialog
                    open
                    title="Are you sure?"
                    message={loading ? 'Removing...' : 'Do you want to delete this follow up?'}
                    loading={loading}
                    onAccept={handleDelete}
                    onCancel={() => setConfirmDelete(false)}
                />
            )}
            <div className="flex justify-center">
                {!enableEdit && (
                    <>
                        <IconButton
                            icon="pen"
                            variant="primary"
                            onClick={() => setEnableEdit(true)}
                        />
                        <IconButton
                            icon="trash"
                            variant="danger"
                            onClick={() => setConfirmDelete(true)}
                        />
                    </>
                )}
                {enableEdit && (
                    <>
                        <IconButton icon="check" variant="success" onClick={handleUpdate} />
                        <IconButton
                            icon="times"
                            variant="danger"
                            onClick={() => setEnableEdit(false)}
                        />
                    </>
                )}
            </div>
        </Dialog>
    )
}

export default FollowUpView
