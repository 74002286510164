import React from 'react'
import ActivityItemRenderer from './ActivityItemRenderer'
import styles from './day-list.module.scss'
import { Button } from '@alejosdev/components'
import { FollowUpsListType } from './types'
import { ActivityFollowUpScheme } from 'types/schemes/activities.scheme'

interface DayListRendererProps {
    items?: FollowUpsListType[]
    loading?: boolean
    onStart?: () => void
    onSelectStart?: (string?: string, duration?: number, isInPast?: 'past' | 'now') => void
    onSelectItem?: (item: ActivityFollowUpScheme) => void
}

const DayListRenderer: React.FC<DayListRendererProps> = ({
    loading,
    items,
    onStart,
    onSelectStart,
    onSelectItem
}) => {
    return (
        <div className={styles.root}>
            {loading && <p>Loading...</p>}
            <div className="flex justify-center items-center flex-col">
                <Button
                    className="flex-grow-0"
                    variant="success"
                    icon="clock"
                    iconPosition="end"
                    onClick={onStart}
                >
                    Register activity
                </Button>
            </div>
            {items?.length === 0 && <p className="text-center mt-4">No follow ups so far</p>}
            <ul>
                {items?.map((item, key) => (
                    <ActivityItemRenderer
                        onSelectItem={onSelectItem}
                        key={key}
                        followUp={item}
                        onSelectStart={onSelectStart}
                    />
                ))}
            </ul>
        </div>
    )
}

export default DayListRenderer
