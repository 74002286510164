import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import store from './store'
import SessionCTXWrapper from './SessionCTXWrapper'
import { SessionProviderProps } from './types'

const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
    return (
        <ReduxProvider store={store}>
            <SessionCTXWrapper>{children}</SessionCTXWrapper>
        </ReduxProvider>
    )
}

export default SessionProvider
