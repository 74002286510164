import { configureStore } from '@reduxjs/toolkit'
import sessionReducer from './session.slice'
import cacheReducer from './cache.slice'
import { writeToCache, writeToSession } from './utils'

const store = configureStore({
    reducer: {
        session: sessionReducer,
        cache: cacheReducer
    }
})

const handleStoreChange = (store: any) => {
    return () => {
        const { session, cache } = store.getState() || {}
        writeToSession(JSON.stringify(session))
        writeToCache(JSON.stringify(cache))
    }
}

export const unsuscribe = store.subscribe(handleStoreChange(store))

export default store
