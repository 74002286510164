import React from 'react'
import { AppRouterProviderProps, RoutingConfigType } from './types'
import { useNavigate } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRouter from './AppRouter'
import { AppRouterCTXProvider } from './ctx'

/**
 * Component to abstract the context from the router, this
 * allows to define the router context in a higher level so
 * we can define the routing functions inside our custom context.
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
const AppRouterContextWrapper: React.FC<AppRouterProviderProps> = ({
    routes,
    authenticated,
    routeAliases,
    layouts
}) => {
    const navigate = useNavigate()
    /**
     * Allows to get the url of a given alias
     * @param path route alias
     * @param config Configuration to pass to the route
     * @returns
     */
    const getUrl = (path: string, config?: RoutingConfigType): string => {
        let url = routeAliases[path]
        const { params } = config || {}
        if (params) {
            Object.keys(params).forEach((key) => {
                const value = params[key]
                url = url.replace(`:${key}`, value)
            })
        }
        return url
    }
    /**
     * Function to redirect to a specific route
     * @param path
     * @param config
     */
    const goTo = (path: string, config?: RoutingConfigType) => {
        const url = getUrl(path, config)
        navigate(url)
    }

    /**
     * Function to do a redirection to a route
     * @param path
     * @param config
     */
    const redirectTo = (path: string, config?: RoutingConfigType) => {
        const url = getUrl(path, config)
        navigate(url, { replace: true })
    }

    /**
     * Function to go back to previous route
     */
    const goBack = () => {
        navigate(-1)
    }

    return (
        <AppRouterCTXProvider
            value={{
                routes,
                authenticated,
                goBack,
                goTo,
                redirectTo,
                layouts
            }}
        >
            <AppRouter />
        </AppRouterCTXProvider>
    )
}

/**
 * Lifted context of the Router
 * @author Alejandro Quiroz <alejandro.devop@gmail.com>
 * @version 1.0.0
 */
const AppRouterProvider: React.FC<AppRouterProviderProps> = ({
    routes,
    authenticated,
    routeAliases,
    layouts
}) => {
    return (
        <>
            <Router>
                <AppRouterContextWrapper
                    routes={routes}
                    authenticated={authenticated}
                    routeAliases={routeAliases}
                    layouts={layouts}
                />
            </Router>
        </>
    )
}

export default AppRouterProvider
