import React from 'react'
import styles from './toolbar.module.scss'
import { IconButton, TextField } from '@alejosdev/components'
import AppHeader from 'components/misc/app-header'
import { useSession } from 'hooks'

const ToolBar: React.FC = () => {
    const { setKey, session } = useSession()
    return (
        <div className={styles.root}>
            {!session?.misc?.menuOpened && (
                <div className={styles.mobileActionButton}>
                    <IconButton
                        icon="bars"
                        onClick={() =>
                            setKey('misc', {
                                ...session?.misc,
                                menuOpened: !session?.misc?.menuOpened
                            })
                        }
                    />
                </div>
            )}
            <AppHeader classes={{ root: styles.appHeaderContent }} />

            <div className={styles.filterWrapper}>
                <TextField
                    leadingIcon="search"
                    classes={{
                        wrapper: styles.searchboxInput
                    }}
                />
            </div>
            <div className={styles.actionsMobile}>
                <IconButton icon="ellipsis-v" className={styles.action} />
            </div>
            <div className={styles.actionsWrapper}>
                <IconButton icon="bell" className={styles.action} />
                <IconButton icon="cogs" className={styles.action} />
                <IconButton icon="user" />
            </div>
        </div>
    )
}

export default ToolBar
