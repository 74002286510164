import React, { useCallback } from 'react'
import { Button, TextField, useForm } from '@alejosdev/components'
import useMutateActivityCategory from 'graphql/use-mutate-activity-category'
import { ActivityCategoryFormType } from 'types/form-types/activities.form-types'

interface ActivityCategoryFormProps {
    onSaved?: (newRecord: any) => void
}

const ActivityCategoryForm: React.FC<ActivityCategoryFormProps> = ({ onSaved }) => {
    const { create, loading } = useMutateActivityCategory()

    const [fields, form, { isValidForm, clearForm }] = useForm<ActivityCategoryFormType>({
        required: ['name'],
        fields: {
            name: {
                label: 'Name',
                placeholder: 'Give a name to the category'
            },
            description: {
                label: 'Description',
                placeholder: 'Brief description of the catgory'
            }
        }
    })

    const handleSubmit = useCallback(async () => {
        try {
            const response = await create(form)
            if (response) {
                onSaved?.(response)
                clearForm()
            }
        } catch (err) {
            console.log('Err: ', err)
        }
    }, [create, form, clearForm, onSaved])

    return (
        <div>
            <TextField {...fields.name} />
            <TextField {...fields.description} />
            <div className="mt-2">
                <Button
                    onClick={handleSubmit}
                    variant="primary"
                    disabled={!isValidForm || loading}
                    loading={loading}
                >
                    Create
                </Button>
                <Button variant="default">Cancel</Button>
            </div>
        </div>
    )
}

export default ActivityCategoryForm
