import moment from 'moment'
import { useCallback } from 'react'

const useDateUtils = () => {
    const getDateDiff = (config: {
        startDate: string
        endDate: string
        format?: string
        diffType?: 'day' | 'month' | 'year' | 'minute' | 'second' | 'hour'
        asString?: boolean
    }) => {
        const { endDate, startDate, format = 'YYYY-MM-DD', diffType = 'day', asString } = config
        const start = moment(startDate, format)
        const end = moment(endDate, format)
        const diff = end.diff(start, diffType)

        if (asString) {
            return diff > 1 ? `${diff} ${diffType}s` : `${diff} ${diffType}`
        }
        return diff
    }

    const getDatePeriods = useCallback(
        (config: {
            dateFrom: string
            dateTo: string
            stepUnit?: 'days' | 'months' | 'years'
            step?: number
        }) => {
            const { dateFrom, dateTo, stepUnit = 'days', step = 1 } = config
            const start = moment(dateFrom)
            const end = moment(dateTo)
            const periods = []

            while (start <= end) {
                let newDate = moment(start)
                periods.push(newDate)
                start.add(step, stepUnit)
            }
            return periods
        },
        []
    )

    const getMonthDays = (config: { date: string; dateFormat?: string }) => {
        const { dateFormat = 'YYYY-MM-DD' } = config
        const dateToProcess = moment(config.date, dateFormat)
        const daysOnWeek = 7
        const firstDayOfWeek = dateToProcess.startOf('month').day()
        const lastDayOfWeek = dateToProcess.endOf('month').day()
        const fillingDays = new Array(firstDayOfWeek).fill({ empty: true })
        const endingDaysFiller = new Array(6 - lastDayOfWeek).fill({ empty: true })
        let days = [
            ...fillingDays,
            ...getDatePeriods({
                dateFrom: dateToProcess.startOf('month').format(dateFormat),
                dateTo: dateToProcess.endOf('month').format(dateFormat),
                step: 1,
                stepUnit: 'days'
            }),
            ...endingDaysFiller
        ]

        const output = []
        for (let i = 0; i < days.length; i += daysOnWeek) {
            output.push(days.slice(i, i + daysOnWeek))
        }
        return output
    }

    const getMinutesFormatted = useCallback((minutes: number) => {
        const hours = Math.floor(minutes / 60)
        const minutesLeft = minutes % 60
        if (hours === 0) {
            return `${minutesLeft}m`
        } else if (hours && minutesLeft === 0) {
            return `${hours}h`
        }
        return `${hours}h ${minutesLeft}m`
    }, [])

    return { getDateDiff, getDatePeriods, getMinutesFormatted, getMonthDays }
}

export default useDateUtils
