import { HabitScheme } from 'types/schemes/habits.scheme'
import useGqlQuery, { GqlOptionsType } from '../useGqlQuery'
import { GET_HABIT_FOLLOW_UPS_QUERY } from './queries'

type VariablesType = {
    my_day?: boolean
    date?: string
    by_habit?: string
    habit_id?: string
    in_dates?: string
}

const useGetHabitFollowUps = (config: { params?: VariablesType } & GqlOptionsType) => {
    return useGqlQuery<VariablesType, HabitScheme[]>(GET_HABIT_FOLLOW_UPS_QUERY, {
        variables: config?.params,
        pagination: false,
        lazy: config?.lazy
    })
}

export default useGetHabitFollowUps
