import { usePost } from '@alejosdev/api-provider'
import { Button, TextField, useForm } from '@alejosdev/components'
import React, { useCallback } from 'react'
import ActivityCategorySelect from '../../form/activity-category-select'

interface ActivityCreateFormProps {
    onSaved?: (newRecord: any) => void
}

const ActivityCreateForm: React.FC<ActivityCreateFormProps> = ({ onSaved }) => {
    const [sendRequest, loading] = usePost('activity.save')
    const [fields, form, { isValidForm, clearForm }] = useForm({
        fields: {
            name: {
                label: 'Name',
                placeholder: 'Give a name to the activity'
            },
            category_id: {
                label: 'Category',
                placeholder: 'Select a category'
            },
            description: {
                label: 'Description',
                placeholder: 'Brief description of the activity'
            }
        }
    })

    const handleSubmit = useCallback(async () => {
        try {
            const { status, data } = await sendRequest(form)
            if (status === true) {
                onSaved?.(data)
                clearForm()
            } else {
                alert('Unknown error')
            }
        } catch (err) {
            console.log('Err: ', err)
        }
    }, [sendRequest, form, clearForm, onSaved])

    return (
        <form>
            <TextField {...fields.name} />
            <ActivityCategorySelect {...fields.category_id} />
            <TextField {...fields.description} />
            <div className="mt-2">
                <Button
                    onClick={handleSubmit}
                    variant="primary"
                    disabled={!isValidForm || loading}
                    loading={loading}
                >
                    Create
                </Button>
                <Button variant="default">Cancel</Button>
            </div>
        </form>
    )
}

export default ActivityCreateForm
