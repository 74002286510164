import { useMemo } from 'react'
import { useTimer } from 'hooks'
import styles from './render-time.module.scss'

interface RenderTimerProps {
    started_date: string
    estimated?: number
    maximized?: boolean
}

const RenderTimer: React.FC<RenderTimerProps> = ({
    started_date,
    estimated: estimatedMins = 0,
    maximized = true
}) => {
    const { elapsed: elapsedMins, time } = useTimer({
        hours: 16,
        initialDate: started_date
    })
    const formattedTime = useMemo(() => {
        const giveFormat = (time: number) => (time > 9 ? time : `0${time}`)
        return {
            hours: giveFormat(time?.hours || 0),
            minutes: giveFormat(time?.minutes || 0),
            seconds: giveFormat(time?.seconds || 0)
        }
    }, [time])

    const percentage = useMemo(() => {
        return (elapsedMins * 100) / estimatedMins
    }, [elapsedMins, estimatedMins])

    const progressInlineStyles = useMemo(() => {
        return {
            width: `${percentage}%`
        }
    }, [percentage])

    return (
        <>
            <div className={styles.root}>
                <span className={styles.number}>{formattedTime?.hours}</span>
                <span className={styles.separator}>:</span>
                <span className={styles.number}>{formattedTime?.minutes}</span>
                <span className={styles.separator}>:</span>
                <span className={styles.number}>{formattedTime?.seconds}</span>
            </div>
            {maximized && (
                <>
                    {estimatedMins > 0 && (
                        <>
                            <div className="flex justify-center">
                                <span className={styles.label}>Estimated</span>
                                <span className={styles.value}>{estimatedMins}m</span>
                            </div>
                            <div className={styles.progress}>
                                <div className={styles.progressBar} style={progressInlineStyles} />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default RenderTimer
