import { useCallback, useState } from 'react'

const useDeviceMode = () => {
    const [mode, setMode] = useState<'dark' | 'light'>('dark')
    const toggleMode = useCallback(() => {
        setMode(mode === 'dark' ? 'light' : 'dark')
    }, [mode])
    return { mode, toggleMode }
}

export default useDeviceMode
