import { ActivityFollowUpScheme } from 'types/schemes/activities.scheme'
import { FollowUpsListType } from './types'
import FollowUpItemRenderer from './FollowUpItemRenderer'
import EmptyActivityFollowUp from './EmptyActivityFollowUp'

interface ActivityItemRendererProps {
    followUp: FollowUpsListType
    onSelectStart?: (start?: string, duration?: number, isInPast?: 'past' | 'now') => void
    onSelectItem?: (item: ActivityFollowUpScheme) => void
}

const ActivityItemRenderer: React.FC<ActivityItemRendererProps> = ({
    followUp,
    onSelectStart,
    onSelectItem
}) => {
    if ('isEmpty' in followUp && typeof followUp.isEmpty === 'boolean') {
        return <EmptyActivityFollowUp item={followUp} onSelectStart={onSelectStart} />
    }
    return (
        <FollowUpItemRenderer
            followUp={followUp as ActivityFollowUpScheme}
            onSelectStart={onSelectStart}
            onSelectItem={() => onSelectItem?.(followUp as ActivityFollowUpScheme)}
        />
    )
}

export default ActivityItemRenderer
