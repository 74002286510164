// import { useSession } from 'hooks'
import moment from 'moment'
import { useMemo } from 'react'
import useGetActivitiesFollowUps from 'graphql/use-get-activities-follow-ups'
import { ActivityFollowUpScheme } from 'types/schemes/activities.scheme'

const useDailyDataProvider = () => {
    const currentDate = useMemo(() => moment().format('YYYY-MM-DD'), [])
    // const { session } = useSession()
    // const { sleepingTrack } = session

    // const sleepingTime = useMemo(() => {
    //     if (!sleepingTrack) return 0
    //     return sleepingTrack.lastSleepingTime || 0
    // }, [sleepingTrack])

    const [followUps] = useGetActivitiesFollowUps({ variables: { date: currentDate } })
    /**
     * Grouped data by Activity name
     */
    const processedData = useMemo(() => {
        let data = followUps?.reduce((accu: any, currentItem: ActivityFollowUpScheme) => {
            if (!accu[currentItem.activity.name]) {
                accu[currentItem.activity.name] = {
                    time: currentItem.time_spent,
                    color: currentItem?.activity?.category?.color
                }
            } else {
                accu[currentItem.activity.name].time += currentItem.time_spent
            }
            return accu
        }, {} as any)

        const labels = Object.keys(data || {})
        const dataVals = Object.keys(data || {})?.map((key: string) => data[key].time)
        const colors = Object.keys(data || {})?.map((key: string) => data[key].color)
        return { labels, data: dataVals, colors }
    }, [followUps])

    const unUsedTime = useMemo(() => {
        const total = 24 * 60
        const used = processedData.data.reduce(
            (acc: number, currentItem: number) => acc + currentItem,
            0
        )
        return total - used
    }, [processedData])

    const pieData = {
        labels: [...processedData.labels, 'Free time', 'Sleeping'],
        datasets: [
            {
                data: [...processedData.data, unUsedTime],
                backgroundColor: [...processedData.colors, '#8f47c01f', '#5a2dc8']
            }
        ]
    }

    const dataForBars: any = useMemo(() => {
        const extractedData = followUps?.reduce((result, currentItem: ActivityFollowUpScheme) => {
            if (!result[currentItem.activity.category.name]) {
                result[currentItem.activity.category.name] = {
                    color: currentItem.activity.category.color,
                    data: currentItem.time_spent
                }
            } else {
                result[currentItem.activity.category.name].data += currentItem.time_spent
            }
            return result
        }, {} as any)
        const sortedentries = Object.entries(extractedData).sort(
            (a: any, b: any) => b[1].data - a[1].data
        )
        const sortedObj: any = {}
        sortedentries.forEach((item: any) => {
            sortedObj[item[0]] = item[1]
        })
        const labels = Object.keys(sortedObj || {})
        const data = Object.keys(sortedObj || {}).map((key: string) => sortedObj[key].data)
        const colors = Object.keys(sortedObj || {}).map((key: string) => sortedObj[key].color)
        return {
            labels,
            datasets: [
                {
                    indexAxis: 'y',
                    label: 'Time spent',
                    data,
                    backgroundColor: colors
                }
            ]
        }
    }, [followUps])

    return {
        pie: pieData,
        bars: dataForBars
    }
}

export default useDailyDataProvider
