import React from 'react'
import MenuPortal from './MenuPortal'
import styles from './menu.module.scss'
import { NavLink } from 'react-router-dom'
import { Icon, IconType } from '@alejosdev/components'
import cs from 'classnames'
import AppHeader from 'components/misc/app-header/AppHeader'
import { useSession } from 'hooks'

const options: { path: string; label: string; icon: IconType }[] = [
    { path: '/', label: 'Dashboard', icon: 'home' },
    { path: '/activities', label: 'Activities', icon: 'clock' },
    { path: '/v2/activities', label: 'Activities (V2)', icon: 'clock' },
    { path: '/tasks', label: 'Tasks', icon: 'clipboard' },
    { path: '/my-todos', label: 'My to-dos', icon: 'clipboard' },
    { path: '/habits', label: 'Habits', icon: 'list-check' },
    { path: '/expenses', label: 'Exspenses', icon: 'money-bill-transfer' },
    { path: '/budgets', label: 'Budgets', icon: 'cart-shopping' },
    { path: '/journal', label: 'Journal', icon: 'book' },
    { path: '/learning', label: 'Learning', icon: 'graduation-cap' },
    { path: '/testing-hall', label: 'Testing hall', icon: 'flask' }
]

const Menu: React.FC = () => {
    const { session } = useSession()

    return (
        <MenuPortal>
            {session?.misc?.menuOpened && <div className={cs(styles.backdrop)}></div>}
            <div
                className={cs(styles.root, {
                    [styles.mobileHidden]: !session?.misc?.menuOpened
                })}
            >
                <AppHeader
                    icon="times"
                    classes={{
                        root: styles.appHeaderMobile
                    }}
                />
                <ul className={styles.menu}>
                    {options?.map((option, key) => (
                        <li key={`${key}-menu`}>
                            <NavLink
                                to={option.path}
                                className={({ isActive }) => {
                                    return cs(styles.navLink, isActive ? styles.active : '')
                                }}
                            >
                                <i className={styles.iconWrapper}>
                                    <Icon icon={option.icon} className={styles.icon} />
                                </i>
                                <span>{option.label}</span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </MenuPortal>
    )
}

export default Menu
