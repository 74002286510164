import { useCallback, useState } from 'react'
import { TypedDocumentNode, useMutation } from 'urql'

/**
 * Hook to centrilize all common methods to graphql
 * @param queries
 * @returns
 */
const useGqlMutation = <CreateType extends object = {}>(queries: {
    create?: string | TypedDocumentNode<any>
    update?: string | TypedDocumentNode<any>
    remove?: string | TypedDocumentNode<any>
}) => {
    const { create, remove, update } = queries
    const [, requestCreate] = useMutation(create || '')
    const [, requestUpdate] = useMutation(update || '')
    const [, requestRemoved] = useMutation(remove || '')
    const [loading, setLoading] = useState(false)

    /**
     * @todo: Handle the error with a logger
     * @param error
     */
    const handleError = (error: any) => {
        const [errorInfo] = error?.graphQLErrors
        const { category, validation } = errorInfo?.extensions
        if (category === 'validation') {
            const [firstKey] = Object.keys(validation)
            const firstError = validation?.[firstKey]
            const message = firstError?.join(', ')
            throw new Error(message)
        } else {
            throw new Error('Graphql error')
        }
    }

    /**
     * Just to call the mutation request function, any preprocess
     * to the payload can be done here.
     */
    const handleCreate = useCallback(async (vars: CreateType) => {
        setLoading(true)
        try {
            const { data, error } = await requestCreate(vars)
            if (error) handleError(error)
            return data.response
        } catch (err: any) {
            console.log('Err: ', err)
            alert(err.message)
        } finally {
            setLoading(false)
        }
    }, [])

    /**
     * Just to call the mutation update function, any preprocess
     * to the payload can be done here.
     */
    const handleUpdate = useCallback(async (vars: CreateType & { id: string }) => {
        setLoading(true)
        try {
            const { data, error } = await requestUpdate(vars)
            if (error) handleError(error)
            return data.response
        } catch (err: any) {
            console.log('Err: ', err)
            alert(err.message)
        } finally {
            setLoading(false)
        }
    }, [])

    /**
     * Just to call the mutation request function, any preprocess
     * to the payload can be done here.
     */
    const handleRemove = useCallback(async (id: string) => {
        setLoading(true)
        try {
            const result = await requestRemoved({
                id
            })
            return result?.data?.response
        } catch (err: any) {
            console.log('Err: ', err)
            alert(err.message)
        } finally {
            setLoading(false)
        }
    }, [])

    return {
        loading,
        create: handleCreate,
        update: handleUpdate,
        remove: handleRemove
    }
}

export default useGqlMutation
