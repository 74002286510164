import { useEffect, useRef } from 'react'

const usePrevProps = <R extends { [key: string]: any }>(value: R): R => {
    const valuesRef = useRef(value)
    useEffect(() => {
        valuesRef.current = value
    })
    return valuesRef.current
}
export default usePrevProps
