import { AppWrapper } from './components'
import SessionProvider from '@alejosdev/session-provider'
import { registerIcons } from '@alejosdev/components'

registerIcons()

function App() {
    return (
        <SessionProvider>
            <AppWrapper />
        </SessionProvider>
    )
}

export default App
