import { ActivityFollowUpScheme } from '../../types/schemes/activities.scheme'
import useGqlQuery, { GqlOptionsType } from '../useGqlQuery'
import { GET_ACTIVITIES_FOLLOW_UPS_QUERY } from './queries'

/**
 * Hook to get the activity follow ups list
 * @returns
 */
const useGetActivitiesFollowUps = (config?: GqlOptionsType<{ date?: string }>) => {
    return useGqlQuery<{ date?: string }, ActivityFollowUpScheme[]>(
        GET_ACTIVITIES_FOLLOW_UPS_QUERY,
        config
    )
}

export default useGetActivitiesFollowUps
