import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faAdd,
    faAnglesLeft,
    faAnglesRight,
    faBan,
    faBars,
    faBell,
    faBrain,
    faBullseye,
    faCalendar,
    faCalendarAlt,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCogs,
    faComment,
    faCreditCard,
    faCreditCardAlt,
    faDroplet,
    faDumbbell,
    faEdit,
    faEllipsisV,
    faEllipsisH,
    faEnvelope,
    faEye,
    faEyeSlash,
    faFilter,
    faFireBurner,
    faFireAlt,
    faFireFlameCurved,
    faFlag,
    faFlask,
    faFloppyDisk,
    faGauge,
    faGlobe,
    faGraduationCap,
    faHeart,
    faHeartCrack,
    faHeartPulse,
    faHeartCirclePlus,
    faHome,
    faImage,
    faList,
    faLock,
    faLockOpen,
    faMinus,
    faPoo,
    faSearch,
    faStar,
    faStarHalf,
    faStarHalfAlt,
    faTag,
    faTimes,
    faTrash,
    faThumbsUp,
    faThumbsDown,
    faUser,
    faHandFist,
    faFilm,
    faBomb,
    faCloud,
    faFile,
    faPen,
    faPenAlt,
    faClipboard,
    faCar,
    faMugHot,
    faGift,
    faBook,
    faBriefcase,
    faShirt,
    faRocket,
    faLanguage,
    faShower,
    faMotorcycle,
    faTooth,
    faBed,
    faUtensils,
    faStopwatch20,
    faClock,
    faWallet,
    faMoneyBill,
    faMoneyBillTransfer,
    faArrowsRotate,
    faQuestionCircle,
    faCarrot,
    faGasPump,
    faComputer,
    faComputerMouse,
    faDog,
    faLightbulb,
    faExclamationTriangle,
    faExclamation,
    faExclamationCircle,
    faCartShopping,
    faListCheck,
    faPalette,
    faPerson,
    faSun,
    faCircle,
    faBoxesStacked,
    faKitchenSet,
    faPlayCircle,
    faPause,
    faStop,
    faSoap,
    faFileInvoice,
    faFileInvoiceDollar,
    faGamepad,
    faBedPulse,
    faMoon,
    faWeightHanging,
    faRuler,
    faGlassWater,
    faRightToBracket,
    faCalendarDay
} from '@fortawesome/free-solid-svg-icons'

export const registerIcons = () => {
    library.add(
        faAdd,
        faAnglesLeft,
        faAnglesRight,
        faArrowsRotate,
        faBan,
        faBell,
        faBars,
        faBed,
        faBedPulse,
        faBomb,
        faBook,
        faBoxesStacked,
        faBriefcase,
        faBrain,
        faBullseye,
        faCarrot,
        faCartShopping,
        faCalendar,
        faCalendarAlt,
        faCalendarDay,
        faCar,
        faCheck,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faChevronUp,
        faCircle,
        faClipboard,
        faClock,
        faCloud,
        faCogs,
        faComment,
        faComputer,
        faComputerMouse,
        faCreditCard,
        faCreditCardAlt,
        faDog,
        faDroplet,
        faDumbbell,
        faFile,
        faFilter,
        faEdit,
        faEllipsisV,
        faEllipsisH,
        faEnvelope,
        faExclamationTriangle,
        faExclamation,
        faExclamationCircle,
        faEye,
        faEyeSlash,
        faFlag,
        faFloppyDisk,
        faFlask,
        faFireBurner,
        faFireAlt,
        faFireFlameCurved,
        faGamepad,
        faGasPump,
        faGauge,
        faGift,
        faGlassWater,
        faGlobe,
        faGraduationCap,
        faHandFist,
        faFilm,
        faHeart,
        faHeartCrack,
        faHeartPulse,
        faHeartCirclePlus,
        faHome,
        faImage,
        faFileInvoice,
        faFileInvoiceDollar,
        faKitchenSet,
        faLanguage,
        faLightbulb,
        faList,
        faListCheck,
        faLock,
        faLockOpen,
        faMinus,
        faMotorcycle,
        faMoneyBill,
        faMoneyBillTransfer,
        faMoon,
        faMugHot,
        faPause,
        faPalette,
        faPen,
        faPenAlt,
        faPerson,
        faPlayCircle,
        faPoo,
        faQuestionCircle,
        faRightToBracket,
        faRocket,
        faRuler,
        faSearch,
        faShirt,
        faShower,
        faSoap,
        faStar,
        faStarHalf,
        faStarHalfAlt,
        faStopwatch20,
        faStop,
        faSun,
        faTag,
        faTimes,
        faTooth,
        faTrash,
        faThumbsUp,
        faThumbsDown,
        faUtensils,
        faUser,
        faWallet,
        faWeightHanging
    )
}
